.Themed.Nautical {   
    --background: hsl(189, 92%, 10%);
    
    --primary: hsl(178, 40%, 90%);
    --accent: hsl(182, 77%, 86%);
    --border: hsl(182, 47%, 86%);

    --text-primary: hsl(210, 5%, 90%);
    --text-secondary: hsl(179, 48%, 55%);
    --text-tertiary: hsl(179, 25%, 15%);
    --text-accent: hsl(179, 25%, 15%);
    

    --primary-hover: hsl(181, 100%, 20%);

    --shadow-hover: hsl(181, 100%, 11%);

    font-family: "BadScript-Regular";
    font-size: 16px;

    .CampaignCard {
        position: relative;
        text-shadow: 
            0.07em 0 black,
            0 0.07em black,
            -0.07em 0 black,
            0 -0.07em black;
    }

    .SessionCard:hover, .SessionNote:hover, .CampaignCard:hover {        
        background: var(--background);
        color: var(--text-primary);
    }    

    .ThemeBackground {
        background-color: rgba(4, 14, 24, 0.55);
    }

    .PageHeaderImage {
        background: url("../../images/campaign_backgrounds/nautical_background1.jpg") no-repeat center;    
        height: 100%;
        width: 100%;    
    }

    .themeBackgroundImage {    
        background: url("../../images/campaign_backgrounds/nautical_background1.jpg") no-repeat center;
        background-size: cover;    
        height: 100%; // if page has no content, background image will be cut off
    }
}


