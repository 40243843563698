.Themed.Mountains {   
    --background: hsl(232, 33%, 30%);  

    //--primary: hsl(209, 35%, 5%);    
    --primary: hsl(4, 100%, 90%);
    --accent: hsl(232, 33%, 60%); 
    --border: hsl(232, 20%, 60%);

    --text-primary: hsl(210, 5%, 90%);
    --text-secondary: hsl(209, 17%, 65%);
    --text-tertiary: hsl(232, 33%, 30%); 
    --text-accent: hsl(232, 33%, 30%);

    --primary-hover: hsl(232, 33%, 22%);

    --shadow-hover: hsl(232, 33%, 60%); 

    font-family: "CarroisGothic-Regular";
        
    .themeBackgroundImage {    
        background: url("../../images/campaign_backgrounds/mountains_background1.png") no-repeat center;
        background-size: cover;    
        height: 100%; // if page has no content, background image will be cut off
    }

    // .SessionCard, .SessionNote {
    //     border-image-source: url("../../images/borders/gothic-border.png");
    //     border-image-slice: 133;
    //     border-image-width: 28px;    
    // }

    .SessionCard:hover, .SessionNote:hover {
        // border-image-source: url("../../images/borders/gothic-border-hover.png");
        // border-image-slice: 133;
        // border-image-width: 28px;
        background: var(--background);
        color: var(--text-primary);
    }

    .CampaignCard {
        color: hsl(4, 100%, 90%);
        --text-primary: hsl(4, 100%, 90%);
        text-shadow: 
            0.07em 0 hsl(232, 33%, 30%),
            0 0.07em hsl(232, 33%, 30%),
            -0.07em 0 hsl(232, 33%, 30%),
            0 -0.07em hsl(232, 33%, 30%);
    }

    .CampaignCard:hover {    
        background: var(--background);
      }

    .ThemeBackground {
        background-color: rgba(24, 24, 24, 0.55);
    }

    .PageHeaderImage {
        background: url("../../images/campaign_backgrounds/mountains_background1.png") no-repeat center;    
        height: 100%;
        width: 100%;    
    }
}


