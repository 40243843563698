@tailwind base;
@tailwind components;
@tailwind utilities;

$primary-50: hsl(262, 61%, 99%);
$primary-100: hsl(262, 61%, 93%);
$primary-200: hsl(262, 68%, 84%);
$primary-300: hsl(262, 54%, 68%);
$primary-400: hsl(262, 47%, 58%);
$primary-500: hsl(262, 43%, 51%);
$primary-600: hsl(262, 48%, 46%);
$primary-700: hsl(262, 60%, 38%);
$primary-800: hsl(262, 69%, 31%);
$primary-900: hsl(262, 72%, 25%);
$primary-950: hsl(262, 85%, 15%);

$accent-100: hsl(165, 67%, 96%);
$accent-200: hsl(163, 75%, 87%);
$accent-300: hsl(162, 73%, 74%);
$accent-400: hsl(164, 70%, 63%);
$accent-500: hsl(166, 64%, 49%);
$accent-600: hsl(168, 78%, 41%);
$accent-700: hsl(170, 91%, 32%);
$accent-800: hsl(172, 94%, 26%);
$accent-900: hsl(172, 98%, 20%);
$accent-950: hsl(176, 100%, 13%);

$gray-100: hsl(210, 33%, 96%);
$gray-200: hsl(212, 15%, 89%);
$gray-300: hsl(210, 16%, 80%);
$gray-400: hsl(211, 13%, 70%);
$gray-500: hsl(209, 10%, 60%);
$gray-600: hsl(210, 12%, 49%);
$gray-700: hsl(209, 14%, 39%);
$gray-800: hsl(209, 18%, 30%);
$gray-900: hsl(211, 20%, 23%);
$gray-950: hsl(209, 24%, 16%);

html,
#root {
  height: 100%;
  color: var(--text-primary); 
}

@font-face {
  font-family: "NotoSans-Regular";
  src: local("NotoSans-Regular"), url("../fonts/NotoSans-Regular.ttf") format("truetype");
}


@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"), url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "BadScript-Regular";
  src: local("BadScript-Regular"), url("../fonts/BadScript-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CarroisGothic-Regular";
  src: local("CarroisGothic-Regular"), url("../fonts/CarroisGothic-Regular.ttf") format("truetype");
}

#body {
  margin: 0;
  height: 100%;
  font-size: 15px;  
  font-family: "OpenSans-Regular";
  //font-family: "NotoSans-Regular";
}

#AppContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.divider {
  padding: 10px 0 10px 0;
}

.PopupDialogBody {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.isBold {
  font-weight: bold !important;
}

.isItalic {
  font-style: italic !important;
}

.isUnderlined {
  text-decoration: underline !important;
}

.isStrikethrough {
  text-decoration: line-through !important;
}

.isStrikethrough.isUnderlined {
  text-decoration: underline line-through !important;
}

.logo {
  fill: var(--primary);
}

#canny-iframe
{
  outline: none;
}

.tooltip-text
{
  display: none;  
}

.tooltip-icon:hover~.tooltip-text{
  display: block;
}

svg {
  stroke-width: 2px;
}