.Themed.Gothic {   
    --background: hsl(210, 5%, 90%);    

    //--primary: hsl(209, 35%, 5%);    
    --primary: hsl(350, 75%, 15%);
    --accent: hsl(350, 94%, 18%);

    --gray: hsl(210, 5%, 15%); 

    --text-primary: hsl(209, 61%, 16%);
    --text-secondary: hsl(210, 5%, 40%); 
    --text-tertiary: hsl(210, 33%, 96%);
    --text-accent: hsl(210, 33%, 96%);

    --primary-hover: hsl(350, 94%, 25%);  

    --shadow-hover: hsl(350, 94%, 8%);

    font-family: "CarroisGothic-Regular";
        
    .themeBackgroundImage {    
        background: url("../../images/campaign_backgrounds/gothic_background1.jpg") no-repeat center;
        background-size: cover;    
        height: 100%; // if page has no content, background image will be cut off
    }

    .SessionCard, .SessionNote {
        border-image-source: url("../../images/borders/gothic-border-black.png");
        border-image-slice: 133;
        border-image-width: 28px;
    }

    .CampaignCard {
        border-image-source: url("../../images/borders/gothic-border-black.png");
        border-image-slice: 133;
        border-image-width: 28px;
        --primary: white;
        --text-primary: white;
        text-shadow: 
            0.07em 0 hsl(350, 75%, 15%),
            0 0.07em hsl(350, 75%, 15%),
            -0.07em 0 hsl(350, 75%, 15%),
            0 -0.07em hsl(350, 75%, 15%);
    }

    .SessionCard:hover, .SessionNote:hover, .CampaignCard:hover {
        border-image-source: url("../../images/borders/gothic-border-white.png");
        border-image-slice: 133;
        border-image-width: 28px;
        background: var(--gray);
        color: var(--text-tertiary);
    }

    .ThemeBackground {
        background-color: rgba(255, 255, 255, 0.63);
    }

    .PageHeaderImage {
        background: url("../../images/campaign_backgrounds/gothic_background1.jpg") no-repeat center;    
        height: 100%;
        width: 100%;    
    }
}


