.Themed.GothicDark {   
    --background: hsl(210, 5%, 0%);    

    //--primary: hsl(209, 35%, 5%);    
    --primary: hsl(210, 33%, 96%);
    --primary: hsl(350, 75%, 25%);
    --accent: hsl(350, 94%, 18%);

    --gray: hsl(210, 5%, 15%); 

    --text-primary: hsl(210, 33%, 96%);
    --text-secondary: hsl(210, 5%, 50%); 
    --text-tertiary: hsl(210, 33%, 96%);
    --text-accent: hsl(210, 33%, 96%);

    --primary-hover: hsl(350, 75%, 25%);

    --shadow-hover: hsl(350, 94%, 8%);

    font-family: "CarroisGothic-Regular";
        
    .themeBackgroundImage {    
        background: url("../../images/campaign_backgrounds/gothicdark3.jpg") no-repeat center; 
        //background: url("../../images/campaign_backgrounds/gothicdark1.jpg") no-repeat center; 
        background-size: cover;
    }

    .SessionCard, .SessionNote {
        border-image-source: url("../../images/borders/gothic-border-white.png");
        border-image-slice: 133;
        border-image-width: 28px;
    }

    .SessionCard:hover, .SessionNote:hover {
        border-image-source: url("../../images/borders/gothic-border-black.png");
        border-image-slice: 133;
        border-image-width: 28px;
        background: var(--gray);
    }

    .CampaignCard {
        border-image-source: url("../../images/borders/gothic-border-white.png");
        border-image-slice: 133;
        border-image-width: 28px;

        --primary: white;
        --text-primary: white;
        text-shadow: 
            0.07em 0 black,
            0 0.07em black,
            -0.07em 0 black,
            0 -0.07em black;
    }

    .CampaignCard:hover {
        border-image-source: url("../../images/borders/gothic-border-white.png");
        border-image-slice: 133;
        border-image-width: 28px;
        background: var(--gray);
    }

    .ThemeBackground {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .PageHeaderImage {
        background: url("../../images/campaign_backgrounds/gothic_background1.jpg") no-repeat center;    
        height: 100%;
        width: 100%;    
    }
}


