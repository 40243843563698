.Themed.Apocalypse {   
    --background: hsl(210, 5%, 10%);    

    //--primary: hsl(209, 35%, 5%);    
    --primary: hsl(350, 77%, 44%);
    --accent: hsl(350, 94%, 12%);
    --shadow: hsl(350, 100%, 6%);

    --text-primary: hsl(210, 5%, 90%);
    --text-secondary: hsl(210, 5%, 90%);
    --text-tertiary: hsl(209, 17%, 90%);
    --text-hover: hsl(210, 5%, 90%);
    --text-accent: hsl(210, 5%, 90%);

    --primary-hover: hsl(350, 94%, 18%);

    --shadow-hover: hsl(350, 94%, 8%);

    font-family: "CarroisGothic-Regular";
        
    .themeBackgroundImage {    
        background: url("../../images/campaign_backgrounds/apocalypse_background1.jpg") no-repeat center;
        background-size: cover;    
        height: 100%; // if page has no content, background image will be cut off
    }

    // .SessionCard, .SessionNote {
    //     border-image-source: url("../../images/borders/gothic-border.png");
    //     border-image-slice: 133;
    //     border-image-width: 28px;    
    // }

    .SessionCard:hover, .SessionNote:hover {
        // border-image-source: url("../../images/borders/gothic-border-hover.png");
        // border-image-slice: 133;
        // border-image-width: 28px;
        background: var(--accent);
    }

    .CampaignCard:hover {    
        background: var(--accent);
    }
    
    .ThemeBackground {
        background-color: rgba(24, 24, 24, 0.85);
    }

    .PageHeaderImage {
        background: url("../../images/campaign_backgrounds/apocalypse_background1.jpg") no-repeat center;    
        height: 100%;
        width: 100%;    
    }
}


