.Themed.Desert {   
    --background: hsl(240, 86%, 3%);

    --primary: hsl(20, 60%, 43%);
    --accent: hsl(20, 50%, 43%, 0.5);
    --shadow: hsl(20, 53%, 12%);

    --text-primary: hsl(210, 5%, 90%);
    --text-secondary: hsl(210, 5%, 90%);
    --text-tertiary: hsl(25, 34%, 85%);
    --text-accent: hsl(210, 5%, 90%);

    --primary-hover: hsl(20, 90%, 43%);

    --shadow-hover: hsl(25, 34%, 55%); 
        
    .themeBackgroundImage {    
        background: url("../../images/campaign_backgrounds/desert_background1.jpg") no-repeat center;
        background-size: cover;    
        height: 100%; // if page has no content, background image will be cut off
    }

    // .SessionCard, .SessionNote {
    //     border-image-source: url("../../images/borders/gothic-border.png");
    //     border-image-slice: 133;
    //     border-image-width: 28px;    
    // }

    .SessionCard:hover, .SessionNote:hover {
        // border-image-source: url("../../images/borders/gothic-border-hover.png");
        // border-image-slice: 133;
        // border-image-width: 28px;
        background: var(--accent);
    }

    .CampaignCard:hover {    
        background: var(--primary);
    }

    .ThemeBackground {
        background-color: rgba(0, 0, 0, 0.55);
    }

    .PageHeaderImage {
        background: url("../../images/campaign_backgrounds/desert_background1.jpg") no-repeat center;    
        height: 100%;
        width: 100%;    
    }
}


