
:root.dark {
  --background: hsl(262, 85%, 5%);

  --text-primary: hsl(210, 33%, 96%);
  --text-secondary:  hsl(164, 70%, 33%);
  --text-accent: hsl(209, 61%, 16%);
  --text-tertiary: hsl(210, 33%, 96%);

  --primary: hsl(262, 48%, 46%);
  --secondary: hsl(262, 69%, 31%);
  --accent: hsl(164, 70%, 63%);

  --gray: hsl(211, 20%, 12%);
  --border: hsl(211, 20%, 23%);
  --shadow: transparent;

  //--primary-hover: hsl(264, 40%, 70%);
  --primary-hover: hsl(262, 85%, 18%);
  --secondary-hover: hsl(264, 61%, 23%);
  --accent-hover: hsl(170, 91%, 32%);
  --gray-hover: hsl(209, 18%, 30%);
  --shadow-hover: transparent;

  --error: hsl(350, 94%, 28%);
  --error-hover : hsl(350, 94%, 18%);

  .ThemeBackground {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .themeBackgroundImage {
    background: url("../../images/campaign_backgrounds/dark_background1.jpg") repeat;
    background-size: auto;
    height: 100%; // if page has no content, background image will be cut off
  }
  
}