:root {
  --background: hsl(210, 33%, 96%);

  --text-primary: hsl(209, 61%, 16%);
  --text-secondary: hsl(209, 17%, 33%);
  --text-tertiary: hsl(262, 61%, 93%);
  --text-accent: hsl(209, 61%, 16%);

  --primary: hsl(262, 69%, 31%);
  --secondary: hsl(262, 61%, 93%);
  --accent: hsl(166, 64%, 49%);

  --gray: hsl(0, 0%, 100%);
  --gray-hover: hsl(210, 12%, 49%);

  --border: hsl(211, 5%, 55%);

  --shadow: hsl(210, 16%, 80%);
  --shadow-hover: hsl(262, 69%, 31%);

  --primary-hover: hsl(262, 85%, 18%);
  --secondary-hover: hsl(262, 69%, 31%);
  --accent-hover: hsl(168, 78%, 41%);

  --error: hsl(350, 94%, 28%);
  --error-hover: hsl(348, 94%, 15%);

  .ThemeBackground {
    background-color: rgba(246, 246, 246, 0.7);
  }

  .SessionCard:hover, .SessionNote:hover, .CampaignCard:hover {       
    background: var(--primary);
    color: var(--text-tertiary);
  }

  

  .themeBackgroundImage {
    background: url("../../images/campaign_backgrounds/default_light_background.webp") repeat;
    background-size: auto;
    height: 100%; // if page has no content, background image will be cut off
  }
}