.Themed.Plains {   
    --background: hsl(78, 38%, 95%);
    
    --primary: hsl(96, 45%, 22%);
    --accent: hsl(96, 45%, 45%);

    --text-primary: hsl(209, 61%, 16%);    
    --text-secondary: hsl(35, 45%, 22%);
    --text-tertiary: hsl(81, 39%, 95%);

    --primary-hover: hsl(232, 33%, 22%);

    --shadow-hover: hsl(232, 33%, 60%); 

    font-family: "CarroisGothic-Regular";
        
    .themeBackgroundImage {    
        background: url("../../images/campaign_backgrounds/plains_background1.jpg") no-repeat center;
        background-size: cover;    
        height: 100%; // if page has no content, background image will be cut off

        // background: hsl(123, 66%, 97%);
        // background-size: cover;    
        // height: 100%; // if page has no content, background image will be cut off
        // border-image-source: url("../../images/borders/plains-border.png");
        // border-image-slice: 146 161 161 146;
        // border-image-width: 0 36px;
        // border-image-repeat: repeat repeat;  
    }

    .SessionCard {
        border-image-source: url("../../images/borders/plains-border.png");
        border-image-slice: 146 161 161 146;
        border-image-width: 15px;
        border-image-repeat: repeat repeat;        
    }

    .SessionCard:hover {
        border-image-source: url("../../images/borders/plains-border-white.png");
        border-image-slice: 146 161 161 146;
        border-image-width: 15px;
        background: var(--accent);
    }

    .SessionCard:hover, .SessionNote:hover, .CampaignCard:hover {        
        background: var(--primary);
        color: var(--text-tertiary);
    }

    .ThemeBackground {
        background-color: rgba(244, 247, 237, 0.65);
    }
}


